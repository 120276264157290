import React, { useEffect, useState } from "react";
import CryptoTicker from "./CryptoTicker";
import CalendarComponent from "./CalendarComponent";
import CalendarToday from "./CalendarToday";
import { fetchLatestNews, shortenText, getSourceLabel } from "./RSSUtilities"; // Import getSourceLabel
import TopShotLogo from "../logos/TopShot-white.svg"; // Replace with your actual path
import PinnacleLogo from "../logos/Pinnacle.png"; // Replace with your actual path
import CryptoKittiesLogo from "../logos/cryptokitties.svg"; // Replace with your actual path
import FlowLogo from "../logos/Flow2.svg";
import { useNavigate } from "react-router-dom"; // For navigation

function HomePage() {
  const navigate = useNavigate(); // For navigation to project pages

  const [newsItems, setNewsItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // Fetch the 5 most recent news items on component mount
  useEffect(() => {
    const loadNews = async () => {
      setIsLoading(true);
      const fetchedNews = await fetchLatestNews(); // Fetch only the latest 5 news items
      setNewsItems(fetchedNews);
      setIsLoading(false);
    };

    loadNews();
  }, []);

  // Google Analytics event tracking
  const trackEventClick = (category, label) => {
    if (window.gtag) {
      window.gtag("event", "click", {
        event_category: category,
        event_label: label,
      });
    }
  };

  // Banners for Explore Projects
  const ExploreProjects = () => (
    <div className="mt-4">
      <h3 className="text-xl font-bold text-gray-800 mb-2">Explore Projects</h3>
      <div className="space-y-4">
        {/* NBA Top Shot Banner */}
        <div className="flex items-center justify-center bg-gradient-to-r from-blue-400 via-gray-400 to-red-400 p-4 rounded-lg shadow-lg h-24">
          <button
            onClick={() => navigate("/topshot")}
            className="hover:scale-105 transition-transform duration-300 p-2 rounded-lg"
          >
            <img src={TopShotLogo} alt="NBA TopShot Logo" className="w-48" />
          </button>
        </div>

        {/* Disney Pinnacle Banner 
        <div className="flex items-center justify-center bg-gradient-to-r from-purple-600 via-blue-600 to-pink-600 p-4 rounded-lg shadow-lg h-24">
          <button
            onClick={() => navigate("/pinnacle")}
            className="hover:scale-105 transition-transform duration-300 p-2 rounded-lg"
          >
            <img
              src={PinnacleLogo}
              alt="Disney Pinnacle Logo"
              className="w-48"
            />
          </button>
        </div>
        */}

        {/* CryptoKitties Banner 
        <div className="flex items-center justify-center bg-rainbow-bands p-4 rounded-lg shadow-lg h-24">
          <button
            onClick={() => navigate("/cryptokitties")}
            className="hover:scale-105 transition-transform duration-300 p-2 rounded-lg flex items-center"
          >
            <img
              src={CryptoKittiesLogo}
              alt="CryptoKitties Logo"
              className="w-24 h-24 rounded-full object-contain mr-4"
            />
            <div className="bg-pink-100 rounded-full p-2">
              <span className="text-gray-900 font-medium text-[16px] leading-[20px] tracking-[-0.3px]">
                CryptoKitties
              </span>
            </div>
          </button>
        </div>
        */}

        {/* Flow Banner 
        <div className="flex items-center justify-center bg-gradient-to-r from-pink-300 via-blue-300 to-teal-300 p-4 rounded-lg shadow-lg h-24">
          <button
            onClick={() => navigate("/flow")}
            className="hover:scale-105 transition-transform duration-300 p-2 rounded-lg"
          >
            <img src={FlowLogo} alt="Flow Logo" className="w-48" />
          </button>
        </div>
        */}
      </div>
    </div>
  );

  return (
    <div className="p-2 bg-white">
      {/* Main Content */}
      <div className="flex flex-wrap">
        {/* Main News Section */}
        <div className="w-full md:w-[65%]">
          <h2 className="text-2xl font-bold">Latest News</h2>
          {isLoading ? (
            <p>Loading news...</p>
          ) : newsItems.length === 0 ? (
            <p>No news items found.</p>
          ) : (
            <ul className="space-y-4">
              {newsItems.map((item) => (
                <li
                  key={item.uniqueId}
                  className="flex flex-col md:flex-row mb-4 p-2 border-b items-stretch"
                >
                  {/* Image section */}
                  <div className="w-full md:w-2/5 flex-shrink-0 mb-4 md:mb-0 md:mr-4">
                    {item.image ? (
                      <img
                        src={item.image}
                        alt={item.title}
                        className="w-full h-full object-contain rounded-md"
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src =
                            "https://via.placeholder.com/150?text=No+Image";
                        }}
                      />
                    ) : (
                      <div className="w-full h-full bg-white border rounded-md"></div>
                    )}
                  </div>
                  {/* News content section */}
                  <div className="flex flex-col justify-start w-full md:w-3/5">
                    <div className="mb-2">
                      <p className="text-sm text-gray-500">
                        {getSourceLabel(item.source, item.url)}
                      </p>
                      <a
                        href={item.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="inline-block text-lg font-semibold text-blue-600 hover:underline mt-1"
                      >
                        {item.title}
                      </a>
                    </div>
                    {/* Only show the description if it's available and not "No description available." */}
                    {item.description &&
                      item.description !== "No description available." && (
                        <p className="mt-2">
                          {shortenText(item.description, 150)}
                        </p>
                      )}
                    <p className="text-gray-500 text-sm mt-2">
                      Published on:{" "}
                      {new Date(item.publishedDate).toLocaleDateString()}
                    </p>
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>

        {/* Sidebar */}
        <div className="w-full md:w-[35%] p-2">
          <div className="mb-2">
            <CryptoTicker />
          </div>
          <div>
            <CalendarToday />
          </div>

          {/* Explore Projects Section */}
          <ExploreProjects />
        </div>
      </div>

      {/* Calendar Section at the Bottom */}
      <div className="mt-2">
        <CalendarComponent />
      </div>
    </div>
  );
}

export default HomePage;
