import React, { useEffect, useState } from "react";
import axios from "axios";

const TopShotChallenges = () => {
  const [challenges, setChallenges] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchChallenges = async () => {
      try {
        const response = await axios.get(
          "https://flowconnectbackend-864654c6a577.herokuapp.com/topshot-active-challenges"
        );
        setChallenges(response.data);
      } catch (err) {
        setError("Failed to fetch challenges");
      } finally {
        setLoading(false);
      }
    };

    fetchChallenges();
  }, []);

  if (loading) {
    return <div className="text-center text-lg font-semibold">Loading...</div>;
  }

  if (error) {
    return <div className="text-center text-red-500">{error}</div>;
  }

  // If there are no challenges, display a friendly message.
  if (challenges.length === 0) {
    return (
      <div className="container mx-auto p-4">
        <h1 className="text-2xl font-bold mb-6 text-center">
          NBA Top Shot Active Challenges
        </h1>
        <div className="text-center text-gray-700">
          It looks like there are no active challenges available right now.
          Please check back later!
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-6 text-center">
        NBA Top Shot Active Challenges
      </h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
        {challenges.map((challenge) => (
          <div
            key={challenge.id}
            className="bg-white shadow-md rounded-lg overflow-hidden"
          >
            {challenge.variableChallenge?.assets?.image && (
              <img
                src={challenge.variableChallenge.assets.image}
                alt={challenge.name}
                className="w-full h-48 object-cover"
              />
            )}
            <div className="p-4">
              <h2 className="text-xl font-semibold mb-2">{challenge.name}</h2>
              <p
                className="text-gray-700 mb-4"
                dangerouslySetInnerHTML={{
                  __html: challenge.description,
                }}
              />
              <p className="text-sm text-gray-500 mb-2">
                <strong>Expiration Date:</strong>{" "}
                {new Date(challenge.expirationDate).toLocaleString()}
              </p>
              <p className="text-sm text-gray-500 mb-4">
                <strong>Type:</strong> {challenge.type}
              </p>
              <div>
                <h3 className="text-lg font-semibold mb-2">
                  Variable Challenge Details
                </h3>
                <p className="text-gray-600 mb-2">
                  <strong>Category:</strong>{" "}
                  {challenge.variableChallenge.Category}
                </p>
                <p className="text-gray-600 mb-2">
                  <strong>Summary:</strong>{" "}
                  {challenge.variableChallenge.summary}
                </p>
                <p className="text-gray-600 mb-2">
                  <strong>Start Date:</strong>{" "}
                  {new Date(
                    challenge.variableChallenge.startDate
                  ).toLocaleString()}
                </p>
              </div>
              <div className="mt-4">
                <h4 className="text-md font-medium">Slots:</h4>
                <ul className="list-disc list-inside">
                  {challenge.variableChallenge.variableSlots.map((slot) => (
                    <li key={slot.id} className="text-gray-600">
                      {slot.label} ({slot.slotType})
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TopShotChallenges;
