import React, { useEffect, useState, createContext } from "react";
import { Outlet, useParams, useNavigate, useLocation } from "react-router-dom";
import TopShotLogo from "../logos/TopShot-white.svg";
import { FaInstagram, FaTwitter, FaDiscord, FaReddit } from "react-icons/fa";
import TopShotSeries from "./TopShotSeries";
import TopShotCommunities from "./TopShotCommunities";
import Timeline from "./Timeline";
import TopShotGaming from "./TopShotGaming";
import TopShotAnalytics from "./TopShotAnalytics";
import TopShotChallenges from "./TopShotChallenges";
import _ from "lodash";

const CACHE_DURATION = 5 * 60 * 1000;
const CACHE_KEY = "topshot_data_cache";

export const TopShotDataContext = createContext();

const loadCache = () => {
  try {
    const cached = localStorage.getItem(CACHE_KEY);
    if (cached) {
      const { data, timestamp } = JSON.parse(cached);
      if (Date.now() - timestamp < CACHE_DURATION) {
        return data;
      }
    }
  } catch (error) {
    console.error("Error loading cache:", error);
  }
  return null;
};

const saveCache = (data) => {
  try {
    localStorage.setItem(
      CACHE_KEY,
      JSON.stringify({
        data,
        timestamp: Date.now(),
      })
    );
  } catch (error) {
    console.error("Error saving cache:", error);
  }
};

const processTopShotData = (series, sets, tiers) => {
  const processedTiers = {};
  tiers.forEach((item) => {
    const defaultTier = item.defaultTier?.toString().toLowerCase() || "unknown";
    if (defaultTier === "none" && item.playTiers?.length > 0) {
      const uniqueTiers = _.chain(item.playTiers)
        .map((play) => play.tier?.toString().toLowerCase())
        .compact()
        .uniq()
        .sort()
        .value();
      processedTiers[item.setID] = uniqueTiers.join("/") || "unknown";
    } else {
      processedTiers[item.setID] = defaultTier;
    }
  });

  return {
    series: series.sort((a, b) => a.id - b.id),
    sets,
    tiers: processedTiers,
  };
};

const TopShot = () => {
  const { seriesId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [topShotData, setTopShotData] = useState(null);

  useEffect(() => {
    const initializeData = async () => {
      const cachedData = loadCache();
      if (cachedData) {
        setTopShotData(cachedData);
        setLoading(false);
        return;
      }

      try {
        const [seriesRes, setsRes, tiersRes] = await Promise.all([
          fetch(
            "https://flowconnectbackend-864654c6a577.herokuapp.com/topshot-series"
          ),
          fetch(
            "https://flowconnectbackend-864654c6a577.herokuapp.com/topshot-sets"
          ),
          fetch(
            "https://flowconnectbackend-864654c6a577.herokuapp.com/topshot-tiers"
          ),
        ]);

        const [series, sets, tiers] = await Promise.all([
          seriesRes.json(),
          setsRes.json(),
          tiersRes.json(),
        ]);

        const processedData = processTopShotData(series, sets, tiers);
        setTopShotData(processedData);
        saveCache(processedData);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    initializeData();
  }, []);

  const pathSegments = location.pathname.split("/").filter(Boolean);
  const activeTab = pathSegments[1] || "moment-explorer";
  const selectedSeries = parseInt(seriesId) || topShotData?.series[0]?.id || 0;

  const navigationTabs = [
    { id: "moment-explorer", label: "Moment Explorer" },
    { id: "communities", label: "Communities" },
    { id: "gaming", label: "Gaming" },
    { id: "challenges", label: "Challenges" },
  ];

  if (error) return <div className="p-4 text-red-500">Error: {error}</div>;
  if (loading) return <div className="text-center py-4">Loading...</div>;

  const mainContent =
    activeTab === "moment-explorer" || activeTab === "series" ? (
      <TopShotSeries
        series={topShotData.series}
        selectedSeries={selectedSeries}
        setData={topShotData.sets}
        tierData={topShotData.tiers}
      />
    ) : activeTab === "communities" ? (
      <TopShotCommunities />
    ) : activeTab === "timeline" ? (
      <Timeline defaultProject="TopShot" />
    ) : activeTab === "gaming" ? (
      <TopShotGaming />
    ) : activeTab === "analytics" ? (
      <TopShotAnalytics />
    ) : activeTab === "challenges" ? (
      <TopShotChallenges />
    ) : null;

  return (
    <TopShotDataContext.Provider value={topShotData}>
      <div className="p-2">
        <div className="flex flex-col lg:flex-row items-center bg-gradient-to-r from-blue-400 via-gray-400 to-red-400 p-6 rounded-lg shadow-lg">
          <a
            href="https://nbatopshot.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="lg:flex-shrink-0 hover:scale-105 transition-transform duration-300 p-2 rounded-lg"
          >
            <img src={TopShotLogo} alt="NBA TopShot Logo" className="h-20" />
          </a>
          <div className="flex flex-wrap justify-center lg:justify-start space-x-4 mt-4 lg:mt-0 lg:ml-8">
            {[
              {
                href: "https://www.instagram.com/nbatopshot/",
                Icon: FaInstagram,
                label: "Instagram",
              },
              { href: "https://x.com/nbatopshot", Icon: FaTwitter, label: "X" },
              {
                href: "https://discord.gg/nbatopshot",
                Icon: FaDiscord,
                label: "Discord",
              },
              {
                href: "https://www.reddit.com/r/nbatopshot/",
                Icon: FaReddit,
                label: "Reddit",
              },
            ].map(({ href, Icon, label }) => (
              <a
                key={label}
                href={href}
                target="_blank"
                rel="noopener noreferrer"
                className="text-white hover:text-purple-200 flex items-center hover:scale-105 transition-transform duration-300"
              >
                <Icon className="h-6 w-6" />
                <span className="ml-2 hidden lg:inline">{label}</span>
              </a>
            ))}
          </div>
        </div>

        <div className="flex space-x-4 mt-4 mb-8 border-b-2 pb-2 overflow-x-auto">
          {navigationTabs.map(({ id, label }) => (
            <button
              key={id}
              onClick={() => navigate(`/topshot/${id}`)}
              className={`px-4 py-2 rounded-lg transition-transform duration-300 hover:scale-105 whitespace-nowrap
                ${
                  activeTab === id
                    ? "bg-green-500 text-white"
                    : "bg-gray-200 text-black"
                }`}
            >
              {label}
            </button>
          ))}
        </div>

        {pathSegments[1] === "sets" ? <Outlet /> : mainContent}
      </div>
    </TopShotDataContext.Provider>
  );
};

export default TopShot;
