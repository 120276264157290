// TopShotHelpers.js

// Cache object to store tier data
let tierCache = null;
let lastFetchTime = 0;
const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes in milliseconds

// Tier priority mapping
const TIER_PRIORITY = {
  ultimate: 1,
  legendary: 2,
  rare: 3,
  fandom: 4,
  common: 5,
};

// Tier color mapping
const TIER_COLORS = {
  ultimate: {
    bg: "from-pink-300 via-purple-300 to-indigo-300",
    class: "bg-gradient-to-r animate-slow-wave",
  },
  legendary: { bg: "from-orange-500 to-orange-500", class: "bg-gradient-to-r" },
  rare: { bg: "from-blue-500 to-blue-500", class: "bg-gradient-to-r" },
  fandom: { bg: "from-lime-500 to-lime-500", class: "bg-gradient-to-r" },
  common: { bg: "from-gray-500 to-gray-500", class: "bg-gradient-to-r" },
};

export function generateCompositeTier(tiers) {
  const validTiers = tiers
    .filter((tier) => tier && tier.toLowerCase() !== "none")
    .map((tier) => tier.toUpperCase());

  const uniqueTiers = [...new Set(validTiers)];

  const TIER_PRIORITY = {
    ULTIMATE: 0,
    LEGENDARY: 1,
    RARE: 2,
    FANDOM: 3,
    COMMON: 4,
  };

  uniqueTiers.sort(
    (a, b) => (TIER_PRIORITY[a] || 99) - (TIER_PRIORITY[b] || 99)
  );

  return uniqueTiers.length === 1 ? uniqueTiers[0] : uniqueTiers.join("/");
}

// Function to fetch and cache tier data
export async function fetchTierData() {
  try {
    const currentTime = Date.now();

    if (tierCache && currentTime - lastFetchTime < CACHE_DURATION) {
      return tierCache;
    }

    const response = await fetch(
      "https://flowconnectbackend-864654c6a577.herokuapp.com/topshot-tiers"
    );
    if (!response.ok) {
      throw new Error("Failed to fetch tier data");
    }

    const data = await response.json();

    const transformedData = data.reduce((acc, item) => {
      // Special handling for sets with "None" as default tier
      const playTiers = item.playTiers.map((play) => play.tier.toLowerCase());

      let setTierInfo = {
        defaultTier: item.defaultTier.toLowerCase(),
        playTiers: item.playTiers.reduce((plays, play) => {
          plays[play.playID] = play.tier.toLowerCase();
          return plays;
        }, {}),
      };

      // If defaultTier is "None" or has no plays, use play tiers
      if (setTierInfo.defaultTier === "none" || playTiers.length > 0) {
        // Generate composite tier from play tiers
        setTierInfo.defaultTier = generateCompositeTier(playTiers);
      }

      acc[item.setID] = setTierInfo;
      return acc;
    }, {});

    tierCache = transformedData;
    lastFetchTime = currentTime;

    return transformedData;
  } catch (error) {
    console.error("Error fetching tier data:", error);
    return {};
  }
}

export async function getEditionTier(setId, playId) {
  try {
    const tiers = await fetchTierData();
    const setData = tiers[setId];

    if (!setData) {
      return "unknown";
    }

    // Check if this play has a specific tier
    if (setData.playTiers && setData.playTiers[playId]) {
      return setData.playTiers[playId];
    }

    return setData.defaultTier || "unknown";
  } catch (error) {
    console.error("Error getting edition tier:", error);
    return "unknown";
  }
}

export async function getTopShotSetTier(setId) {
  try {
    const tiers = await fetchTierData();
    const setData = tiers[setId];

    if (!setData) {
      return "unknown";
    }

    return setData.defaultTier || "unknown";
  } catch (error) {
    console.error("Error getting set tier:", error);
    return "unknown";
  }
}

export function getSetBackground(tier) {
  // Handle single tiers
  if (TIER_COLORS[tier]) {
    return `${TIER_COLORS[tier].class} ${TIER_COLORS[tier].bg}`;
  }

  // Handle composite tiers
  if (tier && tier.includes("/")) {
    const tiersInTier = tier.split("/");

    // If any tier is ultimate, use ultimate gradient
    if (tiersInTier.includes("ultimate")) {
      return TIER_COLORS["ultimate"].class + " " + TIER_COLORS["ultimate"].bg;
    }

    // For two-tier sets, create a gradient
    if (tiersInTier.length === 2) {
      const validTiers = tiersInTier.filter((t) => TIER_COLORS[t]);

      if (validTiers.length === 2) {
        return `bg-gradient-to-r ${TIER_COLORS[validTiers[0]].bg} to-${
          TIER_COLORS[validTiers[1]].bg.split(" ")[1]
        }`;
      }
    }
  }

  return "bg-red-500"; // Fallback for unknown tiers
}

// Image loading functions
export async function loadEditionImage(id) {
  const baseUrl = "https://storage.googleapis.com/flowconnect/topshot/nfts";
  const placeholder = `${baseUrl}/placeholder.png`;
  const imageUrl = `${baseUrl}/${id}.png`;

  return new Promise((resolve) => {
    const img = new Image();
    img.src = imageUrl;
    img.onload = () => resolve(imageUrl);
    img.onerror = () => resolve(placeholder);
  });
}

export async function loadSetImage(setID) {
  const baseUrl = `https://storage.googleapis.com/flowconnect/topshot/sets_small`;
  const placeholderUrl = `${baseUrl}/placeholder.png`;
  const imageUrl = `${baseUrl}/${setID}.jpg`;

  return new Promise((resolve) => {
    const img = new Image();
    img.src = imageUrl;
    img.onload = () => resolve(imageUrl);
    img.onerror = () => resolve(placeholderUrl);
  });
}

// Default export to avoid webpack initialization issues
export default {
  generateCompositeTier,
  fetchTierData,
  getEditionTier,
  getTopShotSetTier,
  getSetBackground,
  loadEditionImage,
  loadSetImage,
};
