// PinnacleHelpers.js
import React from "react";

// About Section Component
export const AboutSection = () => (
  <div className="bg-white p-4 rounded-lg shadow-md mb-4">
    <p className="text-gray-700 mb-4">
      This is a non-commercial, community-driven project created to celebrate
      and share the unique digital collectible offerings available through the{" "}
      <a
        href="https://disneypinnacle.com/"
        target="_blank"
        rel="noopener noreferrer"
        className="text-blue-500 underline"
      >
        Disney Pinnacle™ platform
      </a>
      .
    </p>
    <p className="text-gray-700 mb-4">
      We strive to respect and adhere to all copyright and licensing terms.
    </p>
    <p className="text-gray-700 mb-4">
      Special thanks to{" "}
      <a
        href="https://linktr.ee/diamondflow"
        target="_blank"
        rel="noopener noreferrer"
        className="text-blue-500 underline"
      >
        Diamond
      </a>{" "}
      and{" "}
      <a
        href="https://x.com/RyanKSterling"
        target="_blank"
        rel="noopener noreferrer"
        className="text-blue-500 underline"
      >
        Ryan Sterling
      </a>{" "}
      for their support.
    </p>
    <p className="text-gray-700">
      Join the discussion and provide feedback on our{" "}
      <a
        href="https://discord.gg/5MU4ngQQ"
        target="_blank"
        rel="noopener noreferrer"
        className="text-blue-500 underline"
      >
        Discord
      </a>
      .
    </p>
  </div>
);

// Footer Component
export const Footer = () => (
  <footer className="mt-8">
    <p className="text-sm text-gray-600">
      © Dapper Labs, Inc. | © Disney | © Disney/Pixar | © & ™ LUCASFILM LTD. All
      rights reserved.
      <br />
      App Store is a service mark of Apple Inc. | Google Play is a trademark of
      Google Inc.
    </p>
    <p className="text-sm text-gray-600 mt-2">
      Please note that the images of NFT assets showcased here are for personal,
      non-commercial use only, in accordance with the Terms of Use for Disney
      Pinnacle™. Any modification, commercial use, or unauthorized distribution
      is strictly prohibited.
    </p>
  </footer>
);

export const loadEditionImage = async (id) => {
  const baseUrl = "https://storage.googleapis.com/flowconnect/pinnacle/pins";
  const placeholder = `${baseUrl}/placeholder.png`;
  const imageUrl = `${baseUrl}/${id}.png`;

  return new Promise((resolve) => {
    const img = new Image();
    img.src = imageUrl;
    img.onload = () => resolve(imageUrl);
    img.onerror = () => resolve(placeholder);
  });
};

export const loadSetImage = async (setID) => {
  const baseUrl = `https://storage.googleapis.com/flowconnect/pinnacle/sets`;
  const placeholderUrl = `${baseUrl}/placeholder.png`;
  const imageUrl = `${baseUrl}/${setID}.png`;

  return new Promise((resolve) => {
    const img = new Image();
    img.src = imageUrl;
    img.onload = () => resolve(imageUrl);
    img.onerror = () => resolve(placeholderUrl);
  });
};

export const getSetBackground = (editionType) => {
  // Handle numeric edition types
  if (typeof editionType === "number") {
    switch (editionType) {
      case 1:
        return "bg-gradient-to-r from-pink-300 via-purple-300 to-indigo-300 animate-slow-wave"; // Genesis Edition
      case 2:
        return "bg-blue-300"; // Starter Edition
      case 3:
        return "bg-purple-800"; // Open Edition
      case 4:
        return "bg-blue-300"; // Limited Edition
      case 5:
        return "bg-indigo-400"; // Limited Event Edition
      case 7:
        return "bg-cyan-500"; // Open Event Edition
      default:
        return "bg-gray-100"; // Default fallback
    }
  }
  // Handle string edition types
  else if (typeof editionType === "string") {
    switch (editionType) {
      case "Genesis Edition":
        return "bg-gradient-to-r from-pink-300 via-purple-300 to-indigo-300 animate-slow-wave";
      case "Starter Edition":
        return "bg-indigo-400";
      case "Open Edition":
        return "bg-blue-300";
      case "Limited Edition":
        return "bg-purple-800";
      case "Limited Event Edition":
        return "bg-cyan-500";
      case "Open Event Edition":
        return "bg-cyan-300";
      default:
        return "bg-gray-100";
    }
  }

  return "bg-gray-100"; // Default fallback
};

// Utility function to split set name into studio and actual set name
export const splitSetName = (setName) => {
  if (!setName) return { studio: "Unknown Studio", setName: "Unknown Set" };
  const [studio, set] = setName.split(" • ");
  return {
    studio: studio || "Unknown Studio",
    setName: set || "Unknown Set",
  };
};

// Calculate percentage
export const calculatePercentage = (total, part) => {
  return total > 0 ? (part / total) * 100 : 0;
};

// Format number with commas
export const formatNumberWithCommas = (number) => {
  if (isNaN(number) || number === null || number === undefined) {
    return "0";
  }
  return number.toLocaleString();
};

// Utility function to generate a full circle chart
export const FullCircle = ({
  circulatedPercentage,
  vault1Percentage,
  vault2Percentage,
  burnsPercentage,
}) => {
  return (
    <div className="relative w-36 h-36">
      <div
        className="absolute w-36 h-36 rounded-full border-4 border-gray-300"
        style={{
          background: `conic-gradient(
            #86efac ${circulatedPercentage}%, 
            #fcd34d ${circulatedPercentage}% ${
            circulatedPercentage + vault1Percentage
          }%, 
            #bfdbfe ${circulatedPercentage + vault1Percentage}% ${
            circulatedPercentage + vault1Percentage + vault2Percentage
          }%, 
            #fecaca ${
              circulatedPercentage + vault1Percentage + vault2Percentage
            }% 100%
          )`,
        }}
      />
    </div>
  );
};

// Function to calculate circulated pins
export const calculateCirculated = (
  minted,
  capsuleVault,
  promoVault,
  burn,
  pinnacleVault
) => {
  return minted - capsuleVault - promoVault - burn - pinnacleVault;
};

// Generic data fetch function with error handling
export const fetchDataFromAPI = async (url, errorMessage) => {
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`${errorMessage}: ${response.statusText}`);
    }
    return await response.json();
  } catch (error) {
    console.error(error);
    throw new Error(errorMessage);
  }
};

// Get API endpoint based on edition ID range
export const getApiEndpoint = (editionId) => {
  if (editionId >= 1 && editionId <= 100) {
    return `https://flipsidecrypto.xyz/api/v1/queries/c3e302cd-e938-430d-ba2c-a5d99eaf9a1d/data/latest`;
  } else if (editionId >= 101 && editionId <= 200) {
    return `https://flipsidecrypto.xyz/api/v1/queries/a9fc3be5-740f-4115-aac7-30a50128b096/data/latest`;
  } else if (editionId >= 201 && editionId <= 300) {
    return `https://flipsidecrypto.xyz/api/v1/queries/9389cdb8-fe7f-4b07-8aa3-6e4cd803b0a5/data/latest`;
  } else if (editionId >= 301 && editionId <= 400) {
    return `https://flipsidecrypto.xyz/api/v1/queries/135dea42-350b-431a-9952-298c87ea8572/data/latest`;
  } else if (editionId >= 401 && editionId <= 500) {
    return `https://flipsidecrypto.xyz/api/v1/queries/4234eb7f-da76-4220-83d3-d610654c97b9/data/latest`;
  } else if (editionId >= 501 && editionId <= 600) {
    return `https://flipsidecrypto.xyz/api/v1/queries/ccb38128-075b-48b5-b633-5de00e8eef03/data/latest`;
  }
  return null;
};
