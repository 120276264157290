// Collection.js

import React, { useEffect, useState, useMemo, useCallback } from "react";
import fcl from "../config";
import editionNames from "../data/editionNames.json";
import { loadEditionImage } from "./PinnacleHelpers"; // Changed from GenericHelpers
import { parseUnicode } from "./GenericHelpers"; // Changed from PinnacleHelpers

function Collection() {
  const [user, setUser] = useState(null);
  const [nftCollection, setNftCollection] = useState({});
  const [editionData, setEditionData] = useState([]);
  const [setData, setSetData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({
    variant: "",
    setID: "",
    seriesID: "",
    withSerialNumbers: false,
    onlyChasers: false,
  });
  const [summary, setSummary] = useState({
    totalPins: 0,
    completedSets: [],
    variantBreakdown: {},
  });
  const [imageUrls, setImageUrls] = useState({}); // New state for image URLs

  useEffect(() => {
    fcl.currentUser().subscribe(setUser);
  }, []);

  useEffect(() => {
    if (user?.addr) {
      fetchData();
    }
  }, [user]);

  // Function to fetch all required data
  const fetchData = async () => {
    if (!user || !user.addr) return;

    setLoading(true);
    try {
      const nftResult = await fcl.query({
        cadence: `import NonFungibleToken from 0x1d7e57aa55817448
                  import Pinnacle from 0xedf9df96c92f4595
                  import HybridCustody from 0xd8a7e05a7ac670c0

                  access(all) struct NFTDetails {
                    access(all) let id: UInt64
                    access(all) let editionID: Int
                    access(all) let serialNumber: UInt64?

                    init(id: UInt64, editionID: Int, serialNumber: UInt64?) {
                        self.id = id
                        self.editionID = editionID
                        self.serialNumber = serialNumber
                    }
                  }

                  access(all) fun getNFTsFromAddress(address: Address): [NFTDetails] {
                    let account = getAccount(address)

                    let collectionRef = account
                        .capabilities
                        .borrow<&Pinnacle.Collection>(/public/PinnacleCollection)

                    if collectionRef == nil {
                        return []
                    }

                    let nftIDs = collectionRef!.getIDs()
                    var nftDetailsList: [NFTDetails] = []

                    for id in nftIDs {
                        let nftRef = collectionRef!.borrowPinNFT(id: id)
                            ?? panic("Could not borrow a reference to the NFT")

                        let nftDetails = NFTDetails(
                            id: nftRef.id,
                            editionID: nftRef.editionID,
                            serialNumber: nftRef.serialNumber
                        )

                        nftDetailsList.append(nftDetails)
                    }

                    return nftDetailsList
                  }

                  access(all) fun main(address: Address): [NFTDetails] {
                    var allNftDetails: [NFTDetails] = []

                    let parentNFTs = getNFTsFromAddress(address: address)
                    allNftDetails.appendAll(parentNFTs)

                    let acct = getAuthAccount<auth(Storage) &Account>(address)
                    let manager =
                        acct.storage.borrow<&HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath)
                        ?? panic("Manager not found")

                    let childAddresses = manager.getChildAddresses()

                    for childAddress in childAddresses {
                        let childNFTs = getNFTsFromAddress(address: childAddress)
                        allNftDetails.appendAll(childNFTs)
                    }

                    return allNftDetails
                  }
        `,
        args: (arg, t) => [arg(user.addr, t.Address)],
      });

      const editionsRes = await fetch(
        "https://flowconnectbackend-864654c6a577.herokuapp.com/pinnacle-editions"
      ).then((res) => res.json());

      const setsRes = await fetch(
        "https://flowconnectbackend-864654c6a577.herokuapp.com/pinnacle-sets"
      ).then((res) => res.json());

      const groupedNFTs = groupByEditionID(nftResult);
      const calculatedSummary = calculateSummary(
        groupedNFTs,
        editionsRes,
        setsRes
      );

      setNftCollection(groupedNFTs);
      setEditionData(editionsRes);
      setSetData(setsRes);
      setSummary(calculatedSummary);

      // Fetch image URLs after data is fetched
      fetchImageUrls(groupedNFTs);
    } catch (error) {
      console.error("Error fetching data:", error);
    }

    setLoading(false);
  };

  // Function to fetch image URLs for the editions
  const fetchImageUrls = async (nftGrouped) => {
    const urls = {};
    const editionIDs = Object.keys(nftGrouped);
    for (const editionID of editionIDs) {
      const imageUrl = await loadEditionImage(editionID, "pinnacle");
      urls[editionID] = imageUrl;
    }
    setImageUrls(urls);
  };

  // Group NFTs by editionID
  const groupByEditionID = useCallback((nfts) => {
    const grouped = {};
    nfts.forEach((nft) => {
      if (!grouped[nft.editionID]) {
        grouped[nft.editionID] = [];
      }
      grouped[nft.editionID].push(nft);
    });
    return grouped;
  }, []);

  const calculateSummary = (nftGrouped, editions, sets) => {
    let totalPins = 0;
    const completedSets = [];
    const variantBreakdown = {};
    const ownedSetIDs = new Set();

    for (const editionID in nftGrouped) {
      totalPins += nftGrouped[editionID].length;

      const edition = editions.find((e) => e.id === parseInt(editionID));
      if (edition) {
        ownedSetIDs.add(edition.setID);

        if (!variantBreakdown[edition.variant]) {
          variantBreakdown[edition.variant] = 0;
        }
        variantBreakdown[edition.variant] += nftGrouped[editionID].length;
      }
    }

    ownedSetIDs.forEach((setID) => {
      const editionsInSet = editions.filter((e) => e.setID === setID);
      const ownedEditionsInSet = Object.keys(nftGrouped).filter((editionID) => {
        const edition = editions.find((e) => e.id === parseInt(editionID));
        return edition && edition.setID === setID;
      });

      if (ownedEditionsInSet.length === editionsInSet.length) {
        const completedSet = sets.find((s) => s.id === setID);
        if (completedSet) {
          completedSets.push(
            `${parseUnicode(completedSet.name)} (SetID: ${setID})`
          );
        }
      }
    });

    return { totalPins, completedSets, variantBreakdown };
  };

  const sortedEditionIDs = useMemo(
    () => Object.keys(nftCollection).sort((a, b) => a - b),
    [nftCollection]
  );

  const getEditionInfo = (editionID) => {
    return editionData.find((edition) => edition.id === parseInt(editionID));
  };

  // Retrieve the editionType from the setData based on the setID
  const getEditionType = (setID) => {
    const setInfo = setData.find((set) => set.id === setID);
    return setInfo ? setInfo.editionType : "Unknown";
  };

  // Apply filters
  const filteredEditions = useMemo(() => {
    const filtered = sortedEditionIDs.filter((editionID) => {
      const editionInfo = getEditionInfo(editionID);
      const matchesVariant = filters.variant
        ? editionInfo?.variant === filters.variant
        : true;
      const matchesSetID = filters.setID
        ? editionInfo?.setID === parseInt(filters.setID)
        : true;
      const matchesSeriesID = filters.seriesID
        ? editionInfo?.seriesID === parseInt(filters.seriesID)
        : true;
      const matchesChaser = filters.onlyChasers ? editionInfo?.isChaser : true;
      const matchesSerial = filters.withSerialNumbers
        ? nftCollection[editionID].some((nft) => nft.serialNumber)
        : true;

      return (
        matchesVariant &&
        matchesSetID &&
        matchesSeriesID &&
        matchesChaser &&
        matchesSerial
      );
    });

    return filtered;
  }, [filters, sortedEditionIDs, editionData, nftCollection]);

  const totalPinsInFilteredEditions = filteredEditions.reduce(
    (total, editionID) => total + nftCollection[editionID].length,
    0
  );

  const uniqueVariants = useMemo(
    () => [...new Set(editionData.map((e) => e.variant))],
    [editionData]
  );
  const uniqueSets = useMemo(
    () => [...new Set(editionData.map((e) => e.setID))],
    [editionData]
  );
  const uniqueSeries = useMemo(
    () => [...new Set(editionData.map((e) => e.seriesID))],
    [editionData]
  );

  const seriesMapping = {
    1: "2023",
    2: "2024",
  };

  return (
    <div className="pinnacle-collection bg-gray-100 px-4 py-6">
      <h1 className="text-3xl font-bold mb-6">Pinnacle Collection</h1>

      {/* Summary Section */}
      <div className="summary-section mb-6 grid grid-cols-1 lg:grid-cols-2 gap-6">
        <div>
          <p className="text-xl font-semibold">
            <strong>Total Pins:</strong>{" "}
            <span className="text-2xl">{summary.totalPins}</span>
          </p>
          <p className="text-lg mt-4">
            <strong>Variant Breakdown:</strong>
          </p>
          <ul className="text-lg">
            {Object.entries(summary.variantBreakdown).map(
              ([variant, count]) => (
                <li key={variant} className="mt-2">
                  <strong>{variant}:</strong> {count}
                </li>
              )
            )}
          </ul>
        </div>

        {summary.completedSets.length > 0 && (
          <div className="mt-4 lg:mt-0">
            <strong>Completed Sets:</strong>
            <ul className="text-lg">
              {summary.completedSets.map((setName) => (
                <li key={setName}>• {setName}</li>
              ))}
            </ul>
          </div>
        )}
      </div>

      {/* Filters Section */}
      <div className="filters-section mb-6">
        <h3>Filters:</h3>
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
          <label>
            Series:
            <select
              value={filters.seriesID}
              onChange={(e) =>
                setFilters({ ...filters, seriesID: e.target.value })
              }
              className="border p-2 rounded w-full"
            >
              <option value="">All</option>
              {uniqueSeries.map((seriesID) => (
                <option key={seriesID} value={seriesID}>
                  {seriesMapping[seriesID] || `Series ${seriesID}`}
                </option>
              ))}
            </select>
          </label>

          <label>
            Variant:
            <select
              value={filters.variant}
              onChange={(e) =>
                setFilters({ ...filters, variant: e.target.value })
              }
              className="border p-2 rounded w-full"
            >
              <option value="">All</option>
              {uniqueVariants.map((variant) => (
                <option key={variant} value={variant}>
                  {variant}
                </option>
              ))}
            </select>
          </label>

          <label>
            Set:
            <select
              value={filters.setID}
              onChange={(e) =>
                setFilters({ ...filters, setID: e.target.value })
              }
              className="border p-2 rounded w-full"
            >
              <option value="">All</option>
              {uniqueSets.map((setID) => {
                const setInfo = setData.find((set) => set.id === setID);
                return (
                  <option key={setID} value={setID}>
                    {setInfo
                      ? `${parseUnicode(setInfo.name)} (ID: ${setID})`
                      : setID}
                  </option>
                );
              })}
            </select>
          </label>

          <label className="flex items-center">
            <input
              type="checkbox"
              checked={filters.withSerialNumbers}
              onChange={(e) =>
                setFilters({ ...filters, withSerialNumbers: e.target.checked })
              }
              className="mr-2"
            />
            Only Show Editions with Serial Numbers
          </label>

          {/* Chaser Filter */}
          <label className="flex items-center">
            <input
              type="checkbox"
              checked={filters.onlyChasers}
              onChange={(e) =>
                setFilters({ ...filters, onlyChasers: e.target.checked })
              }
              className="mr-2"
            />
            Only Show Chasers
          </label>
        </div>

        {filteredEditions.length > 0 && (
          <p className="mt-2">
            <strong>
              {filteredEditions.length} edition(s) found containing{" "}
              {totalPinsInFilteredEditions} pin(s).
            </strong>
          </p>
        )}
      </div>

      {loading ? (
        <p>Loading...</p>
      ) : (
        <div className="edition-list mt-6 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
          {filteredEditions.length > 0 ? (
            filteredEditions.map((editionID) => {
              const editionInfo = getEditionInfo(editionID);
              const setName =
                setData.find((set) => set.id === editionInfo?.setID)?.name ||
                "N/A";
              const isChaser = editionInfo?.isChaser;
              const editionType = getEditionType(editionInfo?.setID);

              return (
                <div
                  key={editionID}
                  className={`relative border p-2 rounded-lg shadow-md text-left w-64 h-full flex flex-col justify-between ${getSetBackground(
                    editionType
                  )}`}
                >
                  {/* Total Owned in Large Circle */}
                  <div className="absolute top-2 left-2 bg-blue-500 text-white rounded-full w-12 h-12 flex items-center justify-center text-xl font-bold z-10">
                    {nftCollection[editionID].length}X
                  </div>

                  {/* Edition Image */}
                  <img
                    src={
                      imageUrls[editionID] ||
                      "https://storage.googleapis.com/flowconnect/pinnacle/pins/placeholder.png"
                    }
                    alt={`Edition ${editionID}`}
                    className="w-auto h-48 mx-auto mb-2 object-cover relative"
                  />

                  {/* Edition name with Chaser logic */}
                  <h3
                    className={`text-lg font-bold text-center text-slate-200`}
                  >
                    {editionNames[editionID] || `Edition ID: ${editionID}`}
                    {isChaser && <span className="text-yellow-500"> ★</span>}
                  </h3>

                  {/* Edition Info */}
                  {editionInfo && (
                    <div className="text-center mt-2 text-slate-200">
                      <p className="text-sm">{parseUnicode(setName)}</p>
                      <p className="text-sm">
                        <strong>Edition ID:</strong> {editionID} &nbsp;
                        <strong>Variant:</strong> {editionInfo.variant || "N/A"}
                      </p>
                      <p className="text-sm mt-1">
                        <strong>Series:</strong>{" "}
                        {seriesMapping[editionInfo.seriesID] ||
                          "Unknown Series"}
                      </p>
                      <p className="text-sm mt-1">
                        <strong>Edition Type:</strong> {editionType}
                      </p>
                    </div>
                  )}

                  {/* Serial Numbers */}
                  {nftCollection[editionID].some((nft) => nft.serialNumber) && (
                    <div className="serial-number-list text-sm text-center text-slate-200">
                      <strong>Serial Numbers:</strong>
                      <p className="inline-block">
                        {nftCollection[editionID]
                          .map((nft) => nft.serialNumber)
                          .filter(Boolean)
                          .join(", ")}
                      </p>
                    </div>
                  )}
                </div>
              );
            })
          ) : (
            <p>No NFTs found.</p>
          )}
        </div>
      )}
    </div>
  );
}

export default Collection;
