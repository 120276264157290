import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from "chart.js";
import "chartjs-adapter-date-fns"; // Use the date-fns adapter for Chart.js
import { format, subMonths, subYears } from "date-fns"; // For date manipulation

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale
);

const TopShotFloors = () => {
  const [chartData, setChartData] = useState({});
  const [timeRange, setTimeRange] = useState("Last Year"); // Default to 'Last Year'
  const [filteredData, setFilteredData] = useState({});
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://flipsidecrypto.xyz/api/v1/queries/9cd374e4-08d4-4b97-beb8-5600f5deb891/data/latest" // Replace with your API endpoint
        );

        // Check for HTTP errors
        if (!response.ok) {
          throw new Error(
            `Network response was not ok: ${response.status} ${response.statusText}`
          );
        }

        const data = await response.json();

        // Ensure the response is in the expected format (an array)
        if (!Array.isArray(data)) {
          throw new Error(
            "API response is not in the expected format (array)."
          );
        }

        const activityWeeks = data.map((item) =>
          format(new Date(item.ACTIVITY_WEEK), "yyyy-MM-dd")
        );
        const resultPrices = data.map((item) => item.RESULT);

        setChartData({
          labels: activityWeeks,
          datasets: [
            {
              label: "Floor Price (USD)",
              data: resultPrices,
              borderColor: "rgba(75, 192, 192, 1)", // Teal
              backgroundColor: "rgba(75, 192, 192, 0.2)",
              fill: false,
              pointRadius: 0, // No point markers
            },
          ],
        });
      } catch (err) {
        console.error("Error fetching chart data:", err);
        setError(err.message);
      }
    };

    fetchData();
  }, []);

  // Handle time range filtering
  useEffect(() => {
    if (chartData.labels) {
      const now = new Date();
      let filteredWeeks = chartData.labels;

      switch (timeRange) {
        case "Last Year":
          filteredWeeks = chartData.labels.filter(
            (week) => new Date(week) >= subYears(now, 1)
          );
          break;
        case "Last 6 Months":
          filteredWeeks = chartData.labels.filter(
            (week) => new Date(week) >= subMonths(now, 6)
          );
          break;
        case "Last 3 Months":
          filteredWeeks = chartData.labels.filter(
            (week) => new Date(week) >= subMonths(now, 3)
          );
          break;
        case "Last Month":
          filteredWeeks = chartData.labels.filter(
            (week) => new Date(week) >= subMonths(now, 1)
          );
          break;
        default:
          filteredWeeks = chartData.labels; // All time
      }

      // Filter datasets based on the selected time range
      const updatedDatasets = chartData.datasets.map((dataset) => ({
        ...dataset,
        data: dataset.data.filter((_, idx) =>
          filteredWeeks.includes(chartData.labels[idx])
        ),
      }));

      setFilteredData({
        labels: filteredWeeks,
        datasets: updatedDatasets,
      });
    }
  }, [timeRange, chartData]);

  // If there's an error, display an error message
  if (error) {
    return (
      <div className="p-4 bg-red-100 text-red-700 rounded">
        <h2 className="text-xl font-bold mb-2">Error</h2>
        <p>{error}</p>
      </div>
    );
  }

  return (
    <div className="p-4 bg-white rounded-lg shadow-md">
      <div className="mb-6">
        <h3 className="text-xl font-bold mb-2">TopShot Floors Over Time</h3>

        {/* Description of the logic */}
        <div className="mb-4 p-4 bg-gray-100 rounded">
          <h4 className="font-semibold mb-2">
            How the Floor Price is Calculated
          </h4>
          <p>
            The floor price is calculated by analyzing weekly sales data from
            two different platforms: TopShot and Flowty. For each week, we look
            at sales prices and exclude unusually low prices (which might be
            outliers). Then, the lowest valid price from both platforms is
            chosen for each week. This final value represents the floor price,
            which is the lowest valid sale price of NFTs during that week. Note
            that Flowty data is included starting from January 2023, while
            TopShot data covers earlier periods.
          </p>
        </div>

        {/* Dropdown to select time range */}
        <select
          value={timeRange}
          onChange={(e) => setTimeRange(e.target.value)}
          className="mb-4 p-2 border rounded"
        >
          <option value="All">All time</option>
          <option value="Last Year">Last Year</option>
          <option value="Last 6 Months">Last 6 Months</option>
          <option value="Last 3 Months">Last 3 Months</option>
          <option value="Last Month">Last Month</option>
        </select>

        {filteredData.labels ? (
          <Line
            data={filteredData}
            options={{
              responsive: true,
              plugins: {
                legend: {
                  position: "top",
                },
                tooltip: {
                  enabled: true, // Show tooltip on hover
                  callbacks: {
                    label: function (tooltipItem) {
                      return `${
                        tooltipItem.dataset.label
                      }: $${tooltipItem.raw.toLocaleString()}`;
                    },
                  },
                },
                datalabels: {
                  display: false, // Disable point labels
                },
              },
              scales: {
                x: {
                  type: "time",
                  time: {
                    unit: "month",
                    displayFormats: {
                      month: "MMM yyyy",
                    },
                  },
                  ticks: {
                    maxTicksLimit: 12,
                  },
                },
                y: {
                  beginAtZero: true,
                  title: {
                    display: true,
                    text: "Floor Price (USD)",
                  },
                },
              },
            }}
          />
        ) : (
          <p>Loading chart data...</p>
        )}
      </div>
    </div>
  );
};

export default TopShotFloors;
