// GenericHelpers.js

export const formatDate = (timestamp) => {
  return new Date(timestamp * 1000).toLocaleDateString();
};

export const parseUnicode = (text) => {
  if (!text) return "";
  return text.replace(/\\u\{([0-9A-Fa-f]+)\}/g, (_, code) =>
    String.fromCharCode(parseInt(code, 16))
  );
};

export const getLogoUrl = (name) => {
  // List of known NBA team names
  const nbaTeams = [
    "Hawks",
    "Celtics",
    "Nets",
    "Hornets",
    "Bulls",
    "Cavaliers",
    "Mavericks",
    "Nuggets",
    "Pistons",
    "Warriors",
    "Rockets",
    "Pacers",
    "Clippers",
    "Lakers",
    "Grizzlies",
    "Heat",
    "Bucks",
    "Timberwolves",
    "Pelicans",
    "Knicks",
    "Thunder",
    "Magic",
    "76ers",
    "Suns",
    "Blazers",
    "Kings",
    "Spurs",
    "Raptors",
    "Jazz",
    "Wizards",
  ];

  // List of known NFL team names
  const nflTeams = [
    "49ers",
    "Bears",
    "Bengals",
    "Bills",
    "Broncos",
    "Browns",
    "Buccaneers",
    "Cardinals",
    "Chargers",
    "Chiefs",
    "Colts",
    "Commanders",
    "Cowboys",
    "Dolphins",
    "Eagles",
    "Falcons",
    "Giants",
    "Jaguars",
    "Jets",
    "Lions",
    "Packers",
    "Panthers",
    "Patriots",
    "Raiders",
    "Rams",
    "Ravens",
    "Saints",
    "Seahawks",
    "Steelers",
    "Texans",
    "Titans",
    "Vikings",
  ];

  // List of known calendar sources and their corresponding logo names
  const calendarSources = {
    "TopShot Active Challenges": "TopShotIcon",
    "NBA Games": "NBAIcon",
    "NFL Games": "NFLIcon",
    $BETA: "TopShotBeta",
    "Flow Events": "Flow",
    "Pet Jokic's Horses": "PetJokicsHorses",
  };

  // Check if the name matches a calendar source
  if (calendarSources[name]) {
    let fileExtension = "png";
    if (calendarSources[name] === "Flow") {
      fileExtension = "svg";
    } else if (calendarSources[name] === "NFLIcon") {
      fileExtension = "png";
    }
    return `https://storage.googleapis.com/flowconnect/logos/${calendarSources[name]}.${fileExtension}`;
  }

  const regex = new RegExp(
    `\\b(${[...nbaTeams, ...nflTeams].join("|")})\\b`,
    "i"
  );
  const match = name.match(regex);
  if (!match) {
    return `https://storage.googleapis.com/flowconnect/logos/FlowConnect.png`;
  }

  const formattedName = match[0].replace(/\s+/g, "");
  const fileExtension = nbaTeams.includes(formattedName) ? "png" : "svg";
  return `https://storage.googleapis.com/flowconnect/logos/${formattedName}.${fileExtension}`;
};

export const getPagination = (totalPages, currentPage) => {
  const delta = 2;
  const range = [];

  for (
    let i = Math.max(2, currentPage - delta);
    i <= Math.min(totalPages - 1, currentPage + delta);
    i++
  ) {
    range.push(i);
  }

  if (currentPage - delta > 2) {
    range.unshift("...");
  }
  if (currentPage + delta < totalPages - 1) {
    range.push("...");
  }

  range.unshift(1);
  if (totalPages > 1) {
    range.push(totalPages);
  }

  return range;
};
