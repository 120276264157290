import React from "react";
import TopShotOvertime from "./charts/TopShotOvertime"; // Import from the charts directory
import TopShotBuyersSellers from "./charts/TopShotBuyersSellers";
import TopShotSalesValue from "./charts/TopShotSalesValue";
import TopShotFloors from "./charts/TopShotFloors";

const TopShotAnalytics = () => {
  return (
    <div className="p-4 bg-white rounded-lg shadow-md">
      <div className="mb-6">
        {/* Render the TopShotOvertime chart */}
        <TopShotOvertime />
      </div>
      <div className="mb-6">
        {/* Render the TopShotBuyersSellers chart */}
        <TopShotBuyersSellers />
      </div>
      <div className="mb-6">
        {/* Render the TopShotSalesValue chart */}
        <TopShotSalesValue />
      </div>
      <div className="mb-6">
        {/* Render the TopShotFloors chart */}
        <TopShotFloors />
      </div>
    </div>
  );
};

export default TopShotAnalytics;
