import React from "react";
import { Link } from "react-router-dom";
import { getSetBackground } from "./TopShotHelpers";
import { FaLock } from "react-icons/fa";

const TopShotSeries = ({
  series,
  selectedSeries,
  setData = [],
  tierData = {},
}) => {
  const filteredSets = setData.filter(
    (set) => set.series === selectedSeries && ![155, 156, 158].includes(set.id)
  );

  const TIER_PRIORITY = {
    Common: 2,
    Rare: 1,
    Legendary: 0,
  };

  const formatTier = (tier) => {
    if (!tier) return "";
    return tier
      .split("/")
      .map((t) => t.charAt(0).toUpperCase() + t.slice(1).toLowerCase())
      .sort((a, b) => TIER_PRIORITY[a] - TIER_PRIORITY[b])
      .join("/");
  };

  return (
    <div>
      {/* Series Tabs */}
      <div className="flex space-x-2 mb-2 mt-2">
        {series.map((serie) => (
          <Link
            key={serie.id}
            to={`/topshot/series/${serie.id}`}
            className={`px-3 py-2 rounded-md border transition-transform duration-200 hover:scale-105 ${
              selectedSeries === serie.id
                ? "bg-green-600 text-white"
                : "bg-gray-200 border-green-600 text-black"
            }`}
          >
            {`Series ${serie.id}`}
          </Link>
        ))}
      </div>

      {/* Display Sets */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-1">
        {filteredSets.map((set) => {
          // Safely get the tier, defaulting to undefined
          let tier = tierData[set.id];

          // Special handling for "None" tier sets
          if (!tier || tier.toLowerCase() === "none") {
            const playIDs = set.playIDs || [];

            const validTiers = playIDs
              .map((play) => tierData[play])
              .filter(
                (t) =>
                  t &&
                  t.toLowerCase() !== "none" &&
                  t.toLowerCase() !== "unknown"
              )
              .map(formatTier);

            if (validTiers.length > 0) {
              const uniqueTiers = Array.from(new Set(validTiers));
              uniqueTiers.sort((a, b) => {
                const priorityA = TIER_PRIORITY[a] ?? 99;
                const priorityB = TIER_PRIORITY[b] ?? 99;
                return priorityA - priorityB;
              });
              tier = uniqueTiers.join("/");
            } else {
              tier = "";
            }
          } else {
            tier = formatTier(tier);
          }

          const backgroundColor = getSetBackground(tier.toLowerCase());
          const imageUrl = `https://storage.googleapis.com/flowconnect/topshot/sets_small/${set.id}.jpg`;
          const placeholderUrl =
            "https://storage.googleapis.com/flowconnect/topshot/sets_small/placeholder.jpg";

          return (
            <Link
              to={`/topshot/sets/${set.id}`}
              key={set.id}
              className="no-underline"
            >
              <div
                className={`${backgroundColor} text-slate-200 p-0.5 rounded-lg shadow-md flex flex-col justify-between transition-transform duration-200 hover:scale-105 relative`}
                style={{ height: "260px" }}
              >
                <img
                  src={imageUrl}
                  alt={`${set.name} Image`}
                  className="w-full max-h-52 object-cover rounded-lg"
                  onError={(e) => (e.target.src = placeholderUrl)}
                />
                <div className="absolute top-2 right-2 text-right flex flex-col items-end">
                  <div
                    className={`px-2 py-1 text-xs rounded-lg font-bold ${
                      set.locked
                        ? "bg-red-600 text-white"
                        : "bg-green-600 text-white"
                    } flex items-center`}
                  >
                    {set.locked ? <FaLock className="mr-1" /> : null}{" "}
                    {set.locked ? "Locked" : "Open"}
                  </div>
                </div>
                <p className="text-center font-bold mt-1 text-sm">{set.name}</p>
                <p className="text-center text-xs text-white font-medium mt-1">
                  {tier}
                </p>
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default TopShotSeries;
