import React, { useEffect, useState, useContext, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { UserContext } from "./UserContext";
import { TopShotDataContext } from "./TopShot";
import { FaSpinner } from "react-icons/fa";
import { getSetBackground } from "./TopShotHelpers";
import { parseUnicode } from "./GenericHelpers";

const TopShotSets = () => {
  const { setId } = useParams();
  const navigate = useNavigate();
  // Using the context only for sets/series data; the aggregated API is used for editions, plays, and tiers.
  const topShotData = useContext(TopShotDataContext);
  const { user, nftCollection } = useContext(UserContext);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editions, setEditions] = useState([]);
  const [plays, setPlays] = useState([]); // if needed later
  const [editionTiers, setEditionTiers] = useState({});
  const [imageUrls, setImageUrls] = useState({});
  const imgObserver = useRef(null);

  // Set up IntersectionObserver for lazy loading images
  useEffect(() => {
    imgObserver.current = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const img = entry.target;
          const imgUrl = img.getAttribute("data-src");
          if (img && imgUrl) {
            img.src = imgUrl;
          }
          imgObserver.current.unobserve(img);
        }
      });
    });

    return () => {
      if (imgObserver.current) {
        imgObserver.current.disconnect();
      }
    };
  }, []);

  // Fetch aggregated data once
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        // Use the aggregated endpoint for editions, plays, and tiers
        const res = await fetch(
          "https://flowconnectbackend-864654c6a577.herokuapp.com/topshot-data"
        );
        const data = await res.json();

        // Filter data by the current setId and sort by playOrder
        const filteredEditions = data
          .filter((item) => item.setID === Number(setId))
          .sort((a, b) => a.playOrder - b.playOrder);

        // Save editions directly. Each edition includes play info and tier info.
        setEditions(filteredEditions);

        // Optionally, if you need a separate plays array, you can derive it
        // (assuming each edition represents a unique play in this context)
        setPlays(filteredEditions);

        // Map edition playIDs to their tier (normalized to lowercase)
        const tiers = {};
        filteredEditions.forEach((edition) => {
          tiers[edition.playID] = edition.tier
            ? edition.tier.toLowerCase()
            : "unknown";
        });
        setEditionTiers(tiers);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [setId]);

  // Set image URLs for each edition
  useEffect(() => {
    if (editions.length > 0) {
      const imageMap = editions.reduce((acc, edition) => {
        acc[
          edition.playID
        ] = `https://storage.googleapis.com/flowconnect/topshot/images_small/${edition.setID}_${edition.playID}.jpg`;
        return acc;
      }, {});
      setImageUrls(imageMap);
    }
  }, [editions]);

  if (error) return <div className="p-8 text-red-500">Error: {error}</div>;
  if (loading)
    return (
      <div className="flex justify-center items-center h-screen">
        <FaSpinner className="animate-spin text-4xl text-blue-500" />
        <span className="ml-2 text-xl">Loading...</span>
      </div>
    );

  // Retrieve the current set information (from your existing context)
  const currentSet = topShotData?.sets.find((set) => set.id === Number(setId));

  return (
    <div className="p-8">
      <button
        onClick={() => navigate(`/topshot/series/${currentSet?.series}`)}
        className="mb-4 px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300"
      >
        ← Back
      </button>

      <h1 className="text-3xl font-bold mb-4">
        {parseUnicode(currentSet?.name || "")}
      </h1>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-6">
        {editions.map((edition) => {
          // Determine how many of this edition the user owns
          const ownedCount =
            user?.loggedIn && nftCollection?.[edition.playID]
              ? nftCollection[edition.playID].length
              : 0;

          const editionTier = editionTiers[edition.playID] || "unknown";
          // Using the aggregated data for play information (e.g., FullName)
          // Note: Adjust the property names if needed (e.g., edition.FullName)
          return (
            <div
              key={edition.playID}
              className={`relative border px-4 py-2 rounded-lg shadow-md text-left flex flex-col justify-between transition-transform duration-300 hover:scale-105 ${getSetBackground(
                editionTier
              )} text-slate-200 ${
                ownedCount > 0 ? "border-4 border-green-400" : ""
              }`}
              style={{ minWidth: "260px" }}
            >
              {ownedCount > 0 && (
                <div className="absolute top-2 left-2 bg-green-500 text-white rounded-full w-12 h-12 flex items-center justify-center text-xl font-bold z-10">
                  {ownedCount}X
                </div>
              )}

              <div className="w-full h-[250px] mb-1 overflow-hidden flex items-center justify-center">
                <img
                  src="https://storage.googleapis.com/flowconnect/topshot/placeholder.jpg"
                  data-src={imageUrls[edition.playID]}
                  alt={`Play ${edition.playID}`}
                  loading="lazy"
                  className="w-full h-full object-cover"
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src =
                      "https://storage.googleapis.com/flowconnect/topshot/placeholder.jpg";
                  }}
                  ref={(img) => {
                    if (img && imgObserver.current) {
                      imgObserver.current.observe(img);
                    }
                  }}
                />
              </div>

              <h2 className="text-xl font-bold text-center mt-1">
                {edition.FullName || "Unknown Player"}
              </h2>

              <p className="text-sm mt-1">
                <strong>Play ID:</strong> {edition.playID}
              </p>

              <p className="text-sm mt-1">
                <strong>Tier:</strong> {editionTier}
              </p>

              <p className="text-sm mt-1">
                <strong>Retired:</strong> {edition.retired ? "Yes" : "No"}
              </p>

              <p className="text-sm mt-1">
                <strong>Moment Count:</strong> {edition.momentCount}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TopShotSets;
